import React from "react";
import "../../App.css";
import { useNavigate } from "react-router-dom";
// import LoginBox from ".//LoginBox";

const Navbar = () => {
  const navigate = useNavigate();

  const handleClick = (id) => {
    navigate(`/article/${id}`);
  };
  return (
    <>
      <nav className="navbar GradientBackground1 navbar-expand-sm navbar navbar-dark bg-primary">
        <div className="container">
          <div className="fix" onClick={() => handleClick("home")}>
            <h1 className="navbar-brand">
              <span class="Big">Metaba.info</span>
              <span class="Small align-text-center maplelight">
                {" "}
                | Check Drift Meta/Tier/ShopItem
              </span>
            </h1>
          </div>
          <button
            class="navbar-toggler d-block"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasDarkNavbar"
            aria-controls="offcanvasDarkNavbar"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          {/* <ul className="navbar-nav mr-auto">
          <li className="nav-item Small text-light maplebold">
            <LoginBox />
          </li>
        </ul> */}
        </div>
      </nav>
      <div
        class="offcanvas offcanvas-end text-bg-dark"
        tabindex="-1"
        id="offcanvasDarkNavbar"
        aria-labelledby="offcanvasDarkNavbarLabel"
      >
        <div class="offcanvas-header">
          <h5 class="offcanvas-title maplelight" id="offcanvasDarkNavbarLabel">
            Metaba, community news hub
          </h5>
          <button
            type="button"
            class="btn-close btn-close-white"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div class="offcanvas-body">
          <ul class="navbar-nav justify-content-end flex-grow-1 pe-3">
            <li class="nav-item">
              <div
                class="nav-link active"
                aria-current="page"
                onClick={() => handleClick("home")}
                href="#"
              >
                <h4 class="nav-link maplelight">Home</h4>
              </div>
            </li>
            <li class="nav-item">
              <div onClick={() => handleClick("kartbodytier")}>
                <h4 class="nav-link maplelight" href="#">
                  Check Kart Tier
                </h4>
              </div>
            </li>
            <li class="nav-item">
              <div onClick={() => handleClick("charskindex")}>
                <h4 class="nav-link maplelight">Skin Encyclopedia</h4>
              </div>
            </li>
            <li class="nav-item">
              <div onClick={() => handleClick("kartbodydex")}>
                <h4 class="nav-link maplelight">Kart Encyclopedia</h4>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Navbar;
