import React, { useState } from "react";
import Carousel from "./Carousel";
import ShopListing from "./ShopListing";
import FeaturedNews from "./FeaturedNews";
import Ltabs from "./Ltabs";

// const script = document.createElement("script");
// script.src = "https://cdn.logwork.com/widget/countdown.js";
// script.async = true;
// document.body.appendChild(script);

// const callOtherJavascript = () => {
//   window?.externalObject?.measureTimeOnPage?.();
//   window?.externalObject?.enhanceImages?.();
// };

const Home = () => {
  const [currentTab, setCurrentTab] = useState(1);
  return (
    <div>
      <title>KartRider drift shop item list</title>
      <div className="featured-news">
        <Carousel />
      </div>
      <div className="featured-news">
        <h7 class="text-white"> - </h7>
        <h1 class="text-primary kotrablod">Top Article</h1>
      </div>
      <div className="featured-news">
        <FeaturedNews setCurrentTab={setCurrentTab} />
      </div>
      <div className="featured-news">
        <h7 class="text-white"> - </h7>
        <h1 class="text-primary kotrablod">Current Shop Listing</h1>
      </div>
      <div className="featured-news">
        <ShopListing />
      </div>
      <div className="featured-news">
        <h7 class="text-white"> - </h7>
        <h1 class="text-primary kotrablod">News Hub</h1>
      </div>
      <div className="featured-news">
        <Ltabs currentTab={currentTab} setCurrentTab={setCurrentTab} />
      </div>
      {/* <button type="button" class="btn btn-primary btn-lg kotrablod">
        Read More
        새소식 더보기
      </button> */}
    </div>
  );
};

export default Home;
