import React from "react";
import { useNavigate } from "react-router-dom";

const FeaturedNews = ({ setCurrentTab }) => {
  const navigate = useNavigate();

  const handleClick = (id) => {
    navigate(`/article/${id}`);
  };
  return (
    <div class="row row-cols-3 row-cols-md-3 gy-3">
      <div class="col">
        <div
          class="card h-100 CastShadow"
          onClick={() => handleClick("charskindex")}
        >
          <img src="/img/KartImg13.webp" class="card-img-top" alt="..." />
          <div class="card-body">
            <h5 class="card-title kotrablod">View Character Skins</h5>
            <p class="card-text"></p>
          </div>
        </div>
      </div>
      <div class="col">
        <div
          class="card h-100 CastShadow"
          onClick={() => handleClick("kartbodydex")}
        >
          <img src="/img/KartImg10.webp" class="card-img-top" alt="..." />
          <div class="card-body">
            <h5 class="card-title kotrablod">Kart Encyclopedia</h5>
            <p class="card-text"></p>
          </div>
        </div>
      </div>
      <div class="col">
        <div
          class="card h-100 CastShadow"
          onClick={() => handleClick("kartbodytier")}
        >
          <img src="/img/KartImg11.webp" class="card-img-top" alt="..." />
          <div class="card-body">
            <h5 class="card-title kotrablod">Kart Tier</h5>
            <p class="card-text"></p>
          </div>
        </div>
      </div>
      {/* <div class="col">
        <a href="#nav-tabContent" className="a-custom">
          <div class="card h-100" onClick={() => setCurrentTab(3)}>
            <img src="/img/KartImg11.webp" class="card-img-top" alt="..." />
            <div class="card-body">
              <h5 class="card-title kotrablod">
                Kart Tier
              </h5>
              <p class="card-text"></p>
            </div>
          </div>
        </a>
      </div> */}
    </div>
  );
};

export default FeaturedNews;
