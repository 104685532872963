import React from "react";

const ArticleCard2_En = () => {
  return (
    <>
      <div
        class="card mb-3 CastShadow"
        data-bs-toggle="modal"
        data-bs-target="#exampleModal2"
      >
        <div class="row g-0">
          <div class="col-md-4">
            <img
              src="/img/ThumbNail4_KR.webp"
              class="img-fluid rounded-start"
              alt="..."
            />
          </div>
          <div class="col-md-8">
            <div class="card-body">
              <div class="card-title maplebold ArticleHeader">
                Kartrider: Season 1 Opening related information!
              </div>
              <p class="card-text maplelight">
                Official Season 1 start schedule: March 9, 2023 after
                maintenance. Update includes: - The game is now available on
                console. Xbox One / PlayStation4 - A cart upgrade system is
                added. - Grand Prix added. New Theme: Factory Track is open. -
                Season 1 Racing Pass to be opened.
              </p>
              <p class="card-text maplelight">
                <small class="text-muted">Posted: Feb 2, 2023</small>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        class="modal fade"
        id="exampleModal2"
        tabindex="-1"
        aria-labelledby="exampleModalLabel2"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5 maplebold" id="exampleModalLabel10">
                Kartrider: Season 1 Opening related information!
              </h1>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <img src="/img/ThumbNail4_KR.webp" class="img-fluid" alt="..." />
            <div class="modal-body maplelight">
              Official Season 1 start schedule: March 9, 2023 after maintenance.
              Update includes: - The game is now available on console. Xbox One
              / PlayStation4 - A cart upgrade system is added. - Grand Prix
              added. New Theme: Factory Track is open. - Season 1 Racing Pass to
              be opened.
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button type="button" class="btn btn-danger">
                Report
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ArticleCard2_En;
