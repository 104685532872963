import React from "react";

const ArticleCard3_En = () => {
  return (
    <>
      <div
        class="card mb-3 CastShadow"
        data-bs-toggle="modal"
        data-bs-target="#exampleModal3"
      >
        <div class="row g-0">
          <div class="col-md-4">
            <img
              src="/img/ThumbNail6_EN.webp"
              class="img-fluid rounded-start"
              alt="..."
            />
          </div>
          <div class="col-md-8">
            <div class="card-body">
              <div class="card-title maplebold ArticleHeader">
                KartRider: CBT participation and pre-registration rewards
              </div>
              <p class="card-text maplelight">
                Reward period: January 12, 2023 (Thu) 11:00 (UTC +9) ~ until
                further notice (Rewards can be received even after the start of
                the regular season.)∙ Acquire method: It will be sent to the
                mailboxes of all racers connected during the reward period. ∙
                Compensation Item: Kartrider: Drift balloon item will be updated
                in the future Scheduled 2nd CBT reward "white cloud balloon
                item" to "mine cart" Changed.
              </p>
              <p class="card-text maplelight">
                <small class="text-muted">Posted: Jan 1, 2023</small>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        class="modal fade"
        id="exampleModal3"
        tabindex="-1"
        aria-labelledby="exampleModalLabel3"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5 maplebold" id="exampleModalLabel10">
                KartRider: CBT participation and pre-registration rewards
              </h1>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <img src="/img/ThumbNail6_EN.webp" class="img-fluid" alt="..." />
            <div class="modal-body maplelight">
              Reward period: January 12, 2023 (Thu) 11:00 (UTC +9) ~ until
              further notice (Rewards can be received even after the start of
              the regular season.)∙ Acquire method: It will be sent to the
              mailboxes of all racers connected during the reward period. ∙
              Compensation Item: Kartrider: Drift balloon item will be updated
              in the future Scheduled 2nd CBT reward "white cloud balloon item"
              to "mine cart" Changed.
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button type="button" class="btn btn-danger">
                Report
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ArticleCard3_En;
