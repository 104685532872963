import React from "react";

const KartTierCardsL = () => {
  return (
    <div>
      <div class="card mb-3 CastShadow">
        <div class="row g-0">
          <div class="col-md-4">
            <img
              src="/img/KartBody/KartBody718Boxter.webp"
              class="img-fluid rounded-start"
              alt="..."
            />
          </div>
          <div class="col-md-8">
            <div class="card-body">
              <h5 class="card-title kotrablod">No.1 "718 Boxster"</h5>
              <p class="card-text maplelight">
                "Mid-engined roadsters that unite the sporting spirit of the
                legendary Porsche 718"
              </p>
              <p class="card-text">
                <small class="text-muted maplelight">
                  Obtainable by: Season 1 Login
                </small>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="card mb-3 CastShadow">
        <div class="row g-0">
          <div class="col-md-4">
            <img
              src="/img/KartBody/KartBody11.webp"
              class="img-fluid rounded-start"
              alt="..."
            />
          </div>
          <div class="col-md-8">
            <div class="card-body">
              <h5 class="card-title kotrablod">No.1 "Mine Cart"</h5>
              <p class="card-text maplelight">
                "This cart really went off the rails!"
              </p>
              <p class="card-text">
                <small class="text-muted maplelight">
                  Obtainable by: CBT user / Season1 Login
                </small>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="card mb-3 CastShadow">
        <div class="row g-0">
          <div class="col-md-4">
            <img
              src="/img/KartBody/KartBodyNewyorkbus.webp"
              class="img-fluid rounded-start"
              alt="..."
            />
          </div>
          <div class="col-md-8">
            <div class="card-body">
              <h5 class="card-title kotrablod">No.3 "NYC Tour Bus"</h5>
              <p class="card-text maplelight">
                "Tour tickets are sold out! See New York up close."
              </p>
              <p class="card-text maplelight">
                <small class="text-muted">Obtainable by: Shop</small>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="card mb-3 CastShadow">
        <div class="row g-0">
          <div class="col-md-4">
            <img
              src="/img/KartBody/KartBodyPorsche911.webp"
              class="img-fluid rounded-start"
              alt="..."
            />
          </div>
          <div class="col-md-8">
            <div class="card-body">
              <h5 class="card-title kotrablod">No.4 "911 Turbo S Cabriolet"</h5>
              <p class="card-text maplelight">
                "Incredible performance, whilst being comfortablea and fully
                suitable for everyday use."
              </p>
              <p class="card-text">
                <small class="text-muted maplelight">Obtainable by: Shop</small>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="card mb-3 CastShadow">
        <div class="row g-0">
          <div class="col-md-4">
            <img
              src="/img/KartBody/KartBody16.webp"
              class="img-fluid rounded-start"
              alt="..."
            />
          </div>
          <div class="col-md-8">
            <div class="card-body">
              <h5 class="card-title kotrablod">No.5 "Bunny Buggy"</h5>
              <p class="card-text maplelight">
                "This kart will make you appreciate the power of a fresh, crispy
                carrot."
              </p>
              <p class="card-text">
                <small class="text-muted maplelight">
                  Obtainable by: Luna Login Event
                </small>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default KartTierCardsL;
