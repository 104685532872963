import React from "react";
import ArticleCards from "./ArticleCard_En";
import ArticleCards2 from "./ArticleCard2_En";
import ArticleCards3 from "./ArticleCard3_En";
import ArticleCards4 from "./ArticleCard4_En";
import ArticleCards5 from "./ArticleCard5";
import ArticleCards6 from "./ArticleCard6";
import ArticleCards7 from "./ArticleCard7";
import ArticleCards8 from "./ArticleCard8";
import ArticleCards9 from "./ArticleCard9";
import ArticleEvent from "../article/eventarticle/ArticleEvent_En";
import KartTierCardsL from "./KartTierCardsL";
import "../../App.css";

const Ltabs = ({ currentTab, setCurrentTab }) => {
  return (
    <div className="container" id="custom-nav">
      <nav className="mb-4">
        <div class="nav nav-tabs" id="nav-tab" role="tablist">
          <button
            class="nav-link active"
            id="nav-home-tab"
            data-bs-toggle="tab"
            data-bs-target="#nav-home"
            type="button"
            role="tab"
            aria-controls="nav-home"
            aria-selected="true"
            onClick={() => setCurrentTab(1)}
          >
            <span class="BigEMBold">
              <span
                class={`${
                  currentTab === 1
                    ? "text-primary kotrablod"
                    : "text-muted kotrablod"
                }`}
              >
                <div class="LtabText">
                  Core News
                </div>
              </span>
            </span>
          </button>
          <button
            class="nav-link"
            id="nav-profile-tab"
            data-bs-toggle="tab"
            data-bs-target="#nav-profile"
            type="button"
            role="tab"
            aria-controls="nav-profile"
            aria-selected="false"
            onClick={() => setCurrentTab(2)}
          >
            <span class="BigEM">
              <span
                class={`${
                  currentTab === 2
                    ? "text-primary kotrablod"
                    : "text-muted kotrablod"
                }`}
              >
                <div class="LtabText">
                Event Reward & Code
                </div>
              </span>
            </span>
          </button>
          <button
            class="nav-link"
            id="nav-event-tab"
            data-bs-toggle="tab"
            data-bs-target="#nav-event"
            type="button"
            role="tab"
            aria-controls="nav-event"
            aria-selected="false"
            onClick={() => setCurrentTab(3)}
          >
            <span class="BigEM">
              <span
                class={`${
                  currentTab === 3
                    ? "text-primary kotrablod"
                    : "text-muted kotrablod"
                }`}
              >
                <div class="LtabText">
                Kart Tier
                </div>
              </span>
            </span>
          </button>
          {/* <button
            class="nav-link"
            id="nav-contact-tab"
            data-bs-toggle="tab"
            data-bs-target="#nav-contact"
            type="button"
            role="tab"
            aria-controls="nav-contact"
            aria-selected="false"
            onClick={() => setCurrentTab(4)}
          >
            <span class="BigEM">
              <span
                class={`${
                  currentTab === 4
                    ? "text-primary kotrablod"
                    : "text-muted kotrablod"
                }`}
              >
                영상 추천
              </span>
            </span>
          </button> */}
        </div>
      </nav>
      <div class="tab-content" id="nav-tabContent">
        <div
          class="tab-pane fade show active"
          id="nav-home"
          role="tabpanel"
          aria-labelledby="nav-home-tab"
        >
          <img
            src="/img/banner/NewsBanner.webp"
            class="d-block w-100"
            alt="..."
          />
          <div className="Padding"></div>
          <ArticleCards4 />
          <ArticleCards2 />
          <ArticleCards3 />
        </div>
        <div
          class="tab-pane fade"
          id="nav-profile"
          role="tabpanel"
          aria-labelledby="nav-profile-tab"
        >
          <img
            src="/img/banner/EventBanner.webp"
            class="d-block w-100"
            alt="..."
          />
          <div className="Padding"></div>
          <ArticleCards9 />
          <ArticleCards8 />
          <ArticleCards7 />
          <ArticleCards6 />
          <ArticleCards5 />
          <ArticleEvent />
          <ArticleCards />
        </div>
        <div
          class="tab-pane fade"
          id="nav-event"
          role="tabpanel"
          aria-labelledby="nav-event-tab"
        >
          <img
            src="/img/banner/TierBanner.webp"
            class="d-block w-100"
            alt="..."
          />
          <div className="Padding"></div>
          <KartTierCardsL />
        </div>
        <div
          class="tab-pane fade"
          id="nav-contact"
          role="tabpanel"
          aria-labelledby="nav-contact-tab"
        >
          <img
            src="/img/banner/VideoBanner.webp"
            class="d-block w-100"
            alt="..."
          />
          <div className="Padding"></div>
          <div class="embed-responsive">
            {/* <iframe
              class="embed-responsive-item video"
              src="https://www.youtube.com/embed/zpOULjyy-n8?rel=0"
              allowfullscreen
            ></iframe> */}
          </div>
          <ArticleCards />
        </div>
      </div>
    </div>
  );
};

export default Ltabs;

// import React, { useState } from "react";
// import FeaturedNews from "./FeaturedNews";
// import ArticleCards from "./ArticleCards";
// import KartTierCardsL from "./KartTierCardsL";
// import "../../App.css";

// const Ltabs = () => {
//   const [tabs, setTabs] = useState([
//     { text: "핵심 소식", selected: true },
//     { text: "카트 티어", selected: false },
//     { text: "최신글", selected: false },
//   ]);

//   const [currentTab, setCurrentTab] = useState("핵심 소식");

//   const handleSelected = (index, text) => {
//     const getNewTabs = tabs.map((t, i) => {
//       if (i === index) {
//         t.selected = true;
//       } else {
//         t.selected = false;
//       }
//       return t;
//     });

//     setTabs(getNewTabs);
//     setCurrentTab(text);

//     console.log("git here!!", index, text);
//   };
//   return (
//     <div className="container">
//       <nav className="mb-4">
//         <div class="nav nav-tabs" id="nav-tab" role="tablist">
//           {tabs.map((t, i) => {
//             const { text, selected } = t;
//             return (
//               <button
//                 onClick={() => handleSelected(i, text)}
//                 key={i}
//                 class="nav-link active"
//                 id="nav-home-tab"
//                 data-bs-toggle="tab"
//                 data-bs-target="#nav-home"
//                 type="button"
//                 role="tab"
//                 aria-controls="nav-home"
//                 aria-selected="true"
//               >
//                 <span class="BigEMBold">
//                   <span class={`${selected && "text-primary"} kotrablod`}>
//                     {text}
//                   </span>
//                 </span>
//               </button>
//             );
//           })}
//         </div>
//       </nav>
//       <div class="tab-content" id="nav-tabContent">
//         {currentTab === "핵심 소식" ? (
//           <div>
//             <ArticleCards />
//             <ArticleCards />
//             <ArticleCards />
//           </div>
//         ) : currentTab === "카트 티어" ? (
//           <div>
//             <div class="yellow-box">This is a yellow box.</div>
//             <KartTierCardsL />
//           </div>
//         ) : (
//           currentTab === "최신글" && <div>...</div>
//         )}
//       </div>
//     </div>
//   );
// };
// export default Ltabs;
