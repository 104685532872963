import React from "react";

const Carousel = () => {
  return (
    <div id="carouselExample" class="carousel slide CastShadow">
      <div class="carousel-indicators">
        <button
          type="button"
          data-bs-target="#carouselExample"
          data-bs-slide-to="0"
          class="active"
          aria-current="true"
          aria-label="Slide 1"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselExample"
          data-bs-slide-to="1"
          aria-label="Slide 2"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselExample"
          data-bs-slide-to="2"
          aria-label="Slide 3"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselExample"
          data-bs-slide-to="3"
          aria-label="Slide 4"
        ></button>
      </div>
      <div class="carousel-inner">
        <div class="carousel-item active" id="carouselExample">
          <img src="/img/banner/Banner7.webp" class="d-block w-100 rounded" alt="Hi" />
        </div>
        <div class="carousel-item" id="carouselExample">
          <img
            src="/img/banner/Banner6.webp"
            class="d-block w-100 rounded"
            alt="Hello"
          />
        </div>
        <div class="carousel-item" id="carouselExample">
          <img src="/img/banner/Banner1.webp" class="d-block w-100 rounded" alt="..." />
        </div>
        <div class="carousel-item" id="carouselExample">
          <img src="/img/banner/Banner5.webp" class="d-block w-100 rounded" alt="..." />
        </div>
      </div>
      <button
        class="carousel-control-prev"
        type="button"
        data-bs-target="#carouselExample"
        data-bs-slide="prev"
      >
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button
        class="carousel-control-next"
        type="button"
        data-bs-target="#carouselExample"
        data-bs-slide="next"
      >
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button>
    </div>
  );
};

export default Carousel;
