import React from "react";
import "./App.css";
import Navbar from "./component/layout/Navbar";
import Footer from "./component/layout/Footer";
import { Route, Routes } from "react-router-dom";
import Home from "./component/layout/Home";
import KartBodyTier from "./component/article/KartBodyTier";
import CharSkinDex from "./component/article/CharSkinDex";
import KartBodyDex from "./component/article/KartBodyDex";
// import Login from "./component/article/Login";
// import Register from "./component/article/Register";

function App() {
  return (
    <div className="App">
      <Navbar />
      <main>
        <Routes>
          <Route path="/article/charskindex" element={<CharSkinDex />} />
          <Route path="/article/kartbodydex" element={<KartBodyDex />} />
          <Route path="/article/kartbodytier" element={<KartBodyTier />} />
          <Route path="/article/home" element={<Home />} />
          {/* <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} /> */}
          <Route path="/" element={<Home />} />
        </Routes>
      </main>
      <Footer />
    </div>
  );
}

export default App;
