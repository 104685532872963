import React from "react";

const ArticleCard6 = () => {
  return (
    <>
      <div
        class="card mb-3 CastShadow"
        data-bs-toggle="modal"
        data-bs-target="#exampleModal6"
      >
        <div class="row g-0">
          <div class="col-md-4">
            <img
              src="/img/ThumbNail8.webp"
              class="img-fluid rounded-start"
              alt="..."
            />
          </div>
          <div class="col-md-8">
            <div class="card-body">
              <div class="card-title maplebold ArticleHeader">
                KartRider: Drift Twitch Drops!
              </div>
              <p class="card-text maplelight">
                Claim Twitch Drops while watching Twitch Streamers play
                KartRider: Drift starting on March 8.
              </p>
              <p class="card-text maplelight">
                <small class="text-muted">Posted: March 5, 2023</small>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        class="modal fade"
        id="exampleModal6"
        tabindex="-1"
        aria-labelledby="exampleModalLabel6"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5 maplebold" id="exampleModalLabel10">
                KartRider: Drift Twitch Drops!
              </h1>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <img src="/img/ThumbNail8.webp" class="img-fluid" alt="..." />
            <div class="modal-body maplelight">
              Claim Twitch Drops while watching Twitch Streamers play KartRider:
              Drift starting on March 8.
            </div>
            <img src="/img/TN8-content.webp" class="img-fluid" alt="..." />
            <div class="modal-body maplelight">
              You can unlock the King of the Road Decal, UF-Oh Snap! License
              Plate, and Undercover Sophia by watching a total of 4 hours of
              Twitch streams with the "Drops Enabled" tag before Tuesday, March
              28!
            </div>
            <div class="modal-body maplelight">
              Twitch Drop Campaign Duration: PST/PDT (UTC -8/-7): Wednesday,
              March 8, 2023 (End of Maintenance) - Tuesday, March 28, 2023 6:00
              PM PDT CET/CEST (UTC +1/-2): Thursday, March 9, 2023 (End of
              Maintenance) - Wednesday, March 29, 2023 3:00 AM CEST JST (UTC
              +9): Thursday, March 9, 2023 (End of Maintenance) - Wednesday,
              March 29, 2023 10:00 AM AEDT (UTC +11): Thursday, March 9, 2023
              (End of Maintenance) - Wednesday, March 29, 2023 12:00 PM Link
              your Twitch account to unlock drops at the KartRider: Drift Twitch
              Drops page. Upcoming Twitch Drop Campaigns The next Twitch Drop
              Campaigns will run between the following periods, featuring new
              sets of rewards: March 28, 6:00 PM PT - April 17, 5:59 PM PT April
              17, 6:00 PM PT - May 7, 5:59 PM PT
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button type="button" class="btn btn-danger">
                Report
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ArticleCard6;
