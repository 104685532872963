import React from "react";

const ArticleCard_EN = () => {
  return (
    <>
      <div
        class="card mb-3 CastShadow"
        data-bs-toggle="modal"
        data-bs-target="#exampleModal1"
      >
        <div class="row g-0">
          <div class="col-md-4">
            <img
              src="/img/ThumbNail5_KR.webp"
              class="img-fluid rounded-start"
              alt="..."
            />
          </div>
          <div class="col-md-8">
            <div class="card-body">
              <div class="card-title maplebold ArticleHeader">
                KartRider: Drift Lunar Login Event!
              </div>
              <p class="card-text maplelight">
                On January 23 as a Lunar New Year login event, "Sugar Rabbit" cart
                and 2 "Sugar Rabbit License Plates" rewards can be acquired.
              </p>
              <p class="card-text maplelight">
                <small class="text-muted">Posted: Jan 18, 2023</small>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        class="modal fade"
        id="exampleModal1"
        tabindex="-1"
        aria-labelledby="exampleModalLabel1"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5 maplebold" id="exampleModalLabel10">
                KartRider: Drift Lunar Login Event!
              </h1>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body maplelight">
              On January 23 as a Lunar New Year login event, "Sugar Rabbit" cart
              and 2 "Sugar Rabbit License Plates" rewards can be acquired.
            </div>
            <img
              src="/img/KartBody/KartBody16.webp"
              class="img-fluid"
              alt="..."
            />
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button type="button" class="btn btn-danger">
                Report
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ArticleCard_EN;
