import React from "react";

const KartBodyTier = () => {
  return (
    // <div class = "BG2">
    <div className="featured-news">
      <title>KartRider Drift KartBody Tier</title>
      <meta
        name="description"
        content="KartRider Drift's Kart Tier at Metaba!"
      ></meta>
      <h1 class="text-primary kotrablod">Kart Body Tier</h1>
      <h4 class="text-muted kotrablod">
        Statistics based on high level ranker's pick rate
      </h4>
      <p1 class="text-muted kotrablod">Last Updated: 2023-19-03</p1>
      <h1 class="kotrablod TierTag">
        <span class="badge text-bg-danger">Tier: A</span>
      </h1>
      <div class="row mx-0 row-cols-1 row-cols-md-4 gy-4 featured-news">
        {/* card 6 */}
        <div class="col">
          <div class="card h-100">
            <img
              src="/img/KartBody/KartBody718Boxter.webp"
              class="card-img-top"
              alt="..."
            />
            <div class="card-body">
              <h5 class="card-title kotrablod">718 Boxster</h5>
              <p class="card-text">
                "Mid-engined roadsters that unite the sporting spirit of the
                legendary Porsche 718 with the sports car of tomorrow and
                transfer it to the roads of today's world."
              </p>
            </div>
            <div class="card-footer">
              <small class="text-muted">Last Seen: Shop 23-19-03</small>
            </div>
          </div>
        </div>

        {/* card 6 */}
        <div class="col">
          <div class="card h-100">
            <img
              src="/img/KartBody/KartBody11.webp"
              class="card-img-top"
              alt="..."
            />
            <div class="card-body">
              <h5 class="card-title kotrablod">
                Mine Cart
                {/* 광산 수레 */}
              </h5>
              <p class="card-text">"This cart really went off the rails!"</p>
            </div>
            <div class="card-footer">
              <small class="text-muted">Last Seen: ...</small>
            </div>
          </div>
        </div>

        {/* card 6 */}
        <div class="col">
          <div class="card h-100">
            <img
              src="/img/KartBody/KartBodyNewyorkbus.webp"
              class="card-img-top"
              alt="..."
            />
            <div class="card-body">
              <h5 class="card-title kotrablod">NYC Tour Bus</h5>
              <p class="card-text">
                "Tour tickets are sold out! See New York up close."
              </p>
            </div>
            <div class="card-footer">
              <small class="text-muted">Last Seen: Shop 23-19-03</small>
            </div>
          </div>
        </div>

        {/* card 6 */}
        <div class="col">
          <div class="card h-100">
            <img
              src="/img/KartBody/KartBodyPorsche911.webp"
              class="card-img-top"
              alt="..."
            />
            <div class="card-body">
              <h5 class="card-title kotrablod">911 Turbo S Cabriolet</h5>
              <p class="card-text">
                "Incredible performance, whilst being comfortablea and fully
                suitable for everyday use. The 911 Turbo models continue this
                philosophy"
              </p>
            </div>
            <div class="card-footer">
              <small class="text-muted">Last Seen: Shop 23-19-03</small>
            </div>
          </div>
        </div>
      </div>
      <h1 class="kotrablod TierTag">
        <span class="badge text-bg-danger">Tier: B</span>
      </h1>
      <div class="row mx-0 row-cols-1 row-cols-md-4 gy-4 featured-news">
        {/* card 20 */}
        <div class="col">
          <div class="card h-100">
            <img
              src="/img/KartBody/KartBody16.webp"
              class="card-img-top"
              alt="..."
            />
            <div class="card-body">
              <h5 class="card-title kotrablod">
                Bunny Buggy
                {/* 슈가래빗 */}
              </h5>
              <p class="card-text">
                "This kart will make you appreciate the power of a fresh, crispy
                carrot."
                {/* 달달란 당근의 힘! 발바닥에 강력한 부스터가 샘솟는다. */}
              </p>
            </div>
            <div class="card-footer">
              <small class="text-muted">Last Seen: ...</small>
            </div>
          </div>
        </div>
        {/* card 14 */}
        <div class="col">
          <div class="card h-100">
            <img
              src="/img/KartBody/KartBody8.webp"
              class="card-img-top"
              alt="..."
            />
            <div class="card-body">
              <h5 class="card-title kotrablod">
                Aurora
                {/* 오로라 */}
              </h5>
              <p class="card-text">
                "A flash of dazzling light races across the track!"
                {/* 어두운 세상을 밝힐 신의 장막. 환상적인 아름다움을 뿜내며 트랙을
                  내달린다. */}
              </p>
            </div>
            <div class="card-footer">
              <small class="text-muted">Last Seen: ...</small>
            </div>
          </div>
        </div>
        {/* card 10 */}
        <div class="col">
          <div class="card h-100">
            <img
              src="/img/KartBody/KartBody4.webp"
              class="card-img-top"
              alt="..."
            />
            <div class="card-body">
              <h5 class="card-title kotrablod">
                Justice
                {/* 저스티스 */}
              </h5>
              <p class="card-text">
                "Patrol the streets on the four wheels of Justice"
                {/* 트랙 위의 다크 히어로. 냉혹하게 레이스를 지배한다 */}
              </p>
            </div>
            <div class="card-footer">
              <small class="text-muted">Last Seen: ...</small>
            </div>
          </div>
        </div>
      </div>
      <h1 class="kotrablod TierTag">
        <span class="badge text-bg-warning">Tier: C</span>
      </h1>
      <div class="row mx-0 row-cols-1 row-cols-md-4 gy-4 featured-news">
        {/* card 4 */}
        <div class="col">
          <div class="card h-100">
            <img
              src="/img/KartBody/KartBodyAce.png"
              class="card-img-top"
              alt="..."
            />
            <div class="card-body">
              <h5 class="card-title kotrablod">
                Ace
                {/* 에이스 */}
              </h5>
              <p class="card-text">
                "This classic biplane never goes out of style. Bombs away!"
                {/* "복엽기의 부활, 에이스의 귀환! 이제 곧 미사일 폭격이 시작된다." */}
              </p>
            </div>
            <div class="card-footer">
              <small class="text-muted">Last Seen: Shop 23-19-03</small>
            </div>
          </div>
        </div>
        {/* card 1 */}
        <div class="col ">
          {" "}
          <div class="card h-100">
            <img
              src="/img/KartBody/KartBodyBrutus.webp"
              class="card-img-top"
              alt="..."
            />
            <div class="card-body">
              <h5 class="card-title kotrablod">
                Brutus
                {/* 브루터스 */}
              </h5>
              <p class="card-text">
                "Outrace the competition with overwhelming power!"
                {/* "압도적 엔진, 압도적인 파워. 레이스를 압도한다!" */}
              </p>
            </div>
            <div class="card-footer">
              <small class="text-muted">Last Seen: Shop 23-19-03</small>
            </div>
          </div>
        </div>
        {/* card 22 */}
        <div class="col">
          <div class="card h-100">
            <img
              src="/img/KartBody/KartBody13.webp"
              class="card-img-top"
              alt="..."
            />
            <div class="card-body">
              <h5 class="card-title kotrablod">
                Spike
                {/* 스파이크 */}
              </h5>
              <p class="card-text">
                "Just say no to air resistance!"
                {/* 공기저항 이상무! 그 무엇도 레이싱 방해하지 못한다. */}
              </p>
            </div>
            <div class="card-footer">
              <small class="text-muted">Last Seen: ...</small>
            </div>
          </div>
        </div>
        {/* card 12 */}
        <div class="col">
          <div class="card h-100">
            <img
              src="/img/KartBody/KartBody6.webp"
              class="card-img-top"
              alt="..."
            />
            <div class="card-body">
              <h5 class="card-title kotrablod">Practice Kart</h5>
              <p class="card-text">"It takes practice to become a pro"</p>
              <p class="card-text">
                "Select this kart for the Ultimate Sigma Status"
              </p>
            </div>
            <div class="card-footer">
              <small class="text-muted">Last Seen: ...</small>
            </div>
          </div>
        </div>
      </div>
    </div>
    // </div>
  );
};

export default KartBodyTier;
