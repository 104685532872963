import React from "react";

const ArticleCard5 = () => {
  return (
    <>
      <div
        class="card mb-3 CastShadow"
        data-bs-toggle="modal"
        data-bs-target="#exampleModal5"
      >
        <div class="row g-0">
          <div class="col-md-4">
            <img
              src="/img/ThumbNail7.webp"
              class="img-fluid rounded-start"
              alt="..."
            />
          </div>
          <div class="col-md-8">
            <div class="card-body">
              <div class="card-title maplebold ArticleHeader">
                KartRider: Drift x Porsche Event
              </div>
              <p class="card-text maplelight">
                Racers enjoying KartRider: Drift during Season 1 will get a
                surprise gift. Have fun racing in KartRider: Drift in a cool
                Porsche kart!
              </p>
              <p class="card-text maplelight">
                <small class="text-muted">Posted: March 9, 2023</small>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        class="modal fade"
        id="exampleModal5"
        tabindex="-1"
        aria-labelledby="exampleModalLabel5"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5 maplebold" id="exampleModalLabel10">
                KartRider: Drift x Porsche Event
              </h1>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <img src="/img/ThumbNail7.webp" class="img-fluid" alt="..." />
            <div class="modal-body maplelight">
              Racers enjoying KartRider: Drift during Season 1 will get a
              surprise gift. Have fun racing in KartRider: Drift in a cool
              Porsche kart!
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button type="button" class="btn btn-danger">
                Report
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ArticleCard5;
