import React from "react";
import Slider from "react-slick";

const ShopListing = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    autoplay: true,
    adaptiveHeight: true,
    autoplaySpeed: 5000,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="slick-wrapper">
      {/* <h2> Items </h2> */}
      <Slider {...settings}>
        <div className="px-2">
          <div class="card h-100 CastShadow">
            <img
              src="/img/ShopListing/item21.webp"
              class="card-img-top"
              alt="..."
            />
            <div class="card-body">
              <h5 class="card-title kotrablod hoverblue">Pink Bean Package</h5>
              <button type="button" class="btn btn-info kotrablod ">
                1,500 K-COIN
              </button>
              <p class="card-text"></p>
            </div>
          </div>
          <h7 class="text-white"> - </h7>
        </div>
        <div className="px-2">
          <div class="card h-100 CastShadow">
            <img
              src="/img/ShopListing/item22.webp"
              class="card-img-top"
              alt="..."
            />
            <div class="card-body">
              <h5 class="card-title kotrablod hoverblue">Lucid Package</h5>
              <button type="button" class="btn btn-warning kotrablod ">
                25,000 Lucci
              </button>
              <p class="card-text"></p>
            </div>
          </div>
          <h7 class="text-white"> - </h7>
        </div>
        <div className="px-2">
          <div class="card h-100 CastShadow">
            <img
              src="/img/ShopListing/item20.webp"
              class="card-img-top"
              alt="..."
            />
            <div class="card-body">
              <h5 class="card-title kotrablod hoverblue">
                911 Turbo S Cabriolet
              </h5>
              <button type="button" class="btn btn-info kotrablod ">
                800 K-COIN
              </button>
              <p class="card-text"></p>
            </div>
          </div>
          <h7 class="text-white"> - </h7>
        </div>
        <div className="px-2">
          <div class="card h-100 CastShadow">
            <img
              src="/img/ShopListing/item19.webp"
              class="card-img-top"
              alt="..."
            />
            <div class="card-body">
              <h5 class="card-title kotrablod hoverblue">Cupcake Tobi</h5>
              <button type="button" class="btn btn-info kotrablod ">
                500 K-COIN
              </button>
              <p class="card-text"></p>
            </div>
          </div>
          <h7 class="text-white"> - </h7>
        </div>
        <div className="px-2">
          <div class="card h-100 CastShadow">
            <img
              src="/img/ShopListing/item17.webp"
              class="card-img-top"
              alt="..."
            />
            <div class="card-body">
              <h5 class="card-title kotrablod hoverblue">
                New York Taxi Package
              </h5>
              <button type="button" class="btn btn-info kotrablod ">
                850 K-COIN
              </button>
              <p class="card-text"></p>
            </div>
          </div>
          <h7 class="text-white"> - </h7>
        </div>
        <div className="px-2">
          <div class="card h-100 CastShadow">
            <img
              src="/img/ShopListing/item16.webp"
              class="card-img-top"
              alt="..."
            />
            <div class="card-body">
              <h5 class="card-title kotrablod hoverblue">
                Tunnelmaster Package
              </h5>
              <button type="button" class="btn btn-info kotrablod ">
                500 K-COIN
              </button>
              <p class="card-text"></p>
            </div>
          </div>
          <h7 class="text-white"> - </h7>
        </div>
        <div className="px-2">
          <div class="card h-100 CastShadow">
            <img
              src="/img/ShopListing/item15.webp"
              class="card-img-top"
              alt="..."
            />
            <div class="card-body">
              <h5 class="card-title kotrablod hoverblue">Pine Scout Vivi</h5>
              <button type="button" class="btn btn-info kotrablod ">
                500 K-COIN
              </button>
              <p class="card-text"></p>
            </div>
          </div>
          <h7 class="text-white"> - </h7>
        </div>
        <div className="px-2">
          <div class="card h-100 CastShadow">
            <img
              src="/img/ShopListing/item14.webp"
              class="card-img-top"
              alt="..."
            />
            <div class="card-body">
              <h6 class="card-title kotrablod hoverblue">
                New York Bus Package
              </h6>
              <button type="button" class="btn btn-warning kotrablod ">
                14,500 Lucci
              </button>
              <p class="card-text"></p>
            </div>
          </div>
          <h7 class="text-white"> - </h7>
        </div>
        <div className="px-2">
          <div class="card h-100 CastShadow">
            <img
              src="/img/ShopListing/item18.webp"
              class="card-img-top"
              alt="..."
            />
            <div class="card-body">
              <h6 class="card-title kotrablod hoverblue">
                Speed Reader Package
              </h6>
              <button type="button" class="btn btn-warning kotrablod ">
                7,500 Lucci
              </button>
              <p class="card-text"></p>
            </div>
          </div>
          <h7 class="text-white"> - </h7>
        </div>
        <div className="px-2">
          <div class="card h-100 CastShadow">
            <img
              src="/img/ShopListing/item13.webp"
              class="card-img-top"
              alt="..."
            />
            <div class="card-body">
              <h5 class="card-title kotrablod hoverblue">Prep School Rave</h5>
              <button type="button" class="btn btn-warning kotrablod ">
                7,000 Lucci
              </button>
              <p class="card-text"></p>
            </div>
          </div>
          <h7 class="text-white"> - </h7>
        </div>
        <div className="px-2">
          <div class="card h-100 CastShadow">
            <img
              src="/img/ShopListing/item8.webp"
              class="card-img-top"
              alt="..."
            />
            <div class="card-body">
              <h6 class="card-title kotrablod hoverblue">
                Class President Marid
              </h6>
              <button type="button" class="btn btn-warning kotrablod ">
                7,000 Lucci
              </button>
              <p class="card-text"></p>
            </div>
          </div>
          <h7 class="text-white"> - </h7>
        </div>
        <div className="px-2">
          <div class="card h-100 CastShadow">
            <img
              src="/img/ShopListing/item9.webp"
              class="card-img-top"
              alt="..."
            />
            <div class="card-body">
              <h5 class="card-title kotrablod hoverblue">Deuce Coupe</h5>
              <button type="button" class="btn btn-warning kotrablod ">
                10,000 Lucci
              </button>
              <p class="card-text"></p>
            </div>
          </div>
          <h7 class="text-white"> - </h7>
        </div>
        <div className="px-2">
          <div class="card h-100 CastShadow">
            <img
              src="/img/ShopListing/item3.webp"
              class="card-img-top"
              alt="..."
            />
            <div class="card-body">
              <h5 class="card-title kotrablod">Burning Titan</h5>
              <button type="button" class="btn btn-info kotrablod ">
                500 K-Coin
              </button>
              <p class="card-text"></p>
            </div>
          </div>
          <h7 class="text-white"> - </h7>
        </div>
        <div className="px-2">
          <div class="card h-100 CastShadow">
            <img
              src="/img/ShopListing/item1.webp"
              class="card-img-top"
              alt="..."
            />
            <div class="card-body">
              <h6 class="card-title kotrablod">Phantom Thief Kris</h6>
              <button type="button" class="btn btn-info kotrablod">
                500 K-Coin
              </button>
              <p class="card-text"></p>
            </div>
          </div>
          <h7 class="text-white"> - </h7>
        </div>
        <div className="px-2">
          <div class="card h-100 CastShadow">
            <img
              src="/img/ShopListing/item2.webp"
              class="card-img-top"
              alt="..."
            />
            <div class="card-body">
              <h5 class="card-title kotrablod">Brutus Package</h5>
              <button type="button" class="btn btn-warning kotrablod ">
                7,500 Lucci
              </button>
              <p class="card-text"></p>
            </div>
          </div>
          <h7 class="text-white"> - </h7>
        </div>
        <div className="px-2">
          <div class="card h-100 CastShadow">
            <img
              src="/img/ShopListing/item4.webp"
              class="card-img-top"
              alt="..."
            />
            <div class="card-body">
              <h5 class="card-title kotrablod">Ace Package</h5>
              <button type="button" class="btn btn-warning kotrablod ">
                10,000 Lucci
              </button>
              <p class="card-text"></p>
            </div>
          </div>
          <h7 class="text-white"> - </h7>
        </div>
        <div className="px-2">
          <div class="card h-100 CastShadow">
            <img
              src="/img/ShopListing/item6.webp"
              class="card-img-top"
              alt="..."
            />
            <div class="card-body">
              <h5 class="card-title kotrablod">Storm Package</h5>
              <button type="button" class="btn btn-info kotrablod ">
                200 K-Coin
              </button>
              <p class="card-text"></p>
            </div>
          </div>
          <h7 class="text-white"> - </h7>
        </div>
        <div className="px-2">
          <div class="card h-100 CastShadow">
            <img
              src="/img/ShopListing/item5.webp"
              class="card-img-top"
              alt="..."
            />
            <div class="card-body">
              <h5 class="card-title kotrablod">Chef Mos</h5>
              <button type="button" class="btn btn-warning kotrablod ">
                12,000 Lucci
              </button>
              <p class="card-text"></p>
            </div>
          </div>
          <h7 class="text-white"> - </h7>
        </div>
        <div className="px-2">
          <div class="card h-100 CastShadow">
            <img
              src="/img/ShopListing/item11.webp"
              class="card-img-top"
              alt="..."
            />
            <div class="card-body">
              <h6 class="card-title kotrablod">Power Forward Rex</h6>
              <button type="button" class="btn btn-warning kotrablod ">
                7,000 Lucci
              </button>
              <p class="card-text"></p>
            </div>
          </div>
          <h7 class="text-white"> - </h7>
        </div>
        <div className="px-2">
          <div class="card h-100 CastShadow">
            <img
              src="/img/ShopListing/item7.webp"
              class="card-img-top"
              alt="..."
            />
            <div class="card-body">
              <h5 class="card-title kotrablod">Pro Baller Bazzi</h5>
              <button type="button" class="btn btn-warning kotrablod ">
                7,000 Lucci
              </button>
              <p class="card-text"></p>
            </div>
          </div>
          <h7 class="text-white"> - </h7>
        </div>
        <div className="px-2">
          <div class="card h-100 CastShadow">
            <img
              src="/img/ShopListing/item12.webp"
              class="card-img-top"
              alt="..."
            />
            <div class="card-body">
              <h6 class="card-title kotrablod">Ace Adventurer Brian</h6>
              <button type="button" class="btn btn-warning kotrablod ">
                12,000 Lucci
              </button>
              <p class="card-text"></p>
            </div>
          </div>
          <h7 class="text-white"> - </h7>
        </div>
      </Slider>
    </div>
  );
};

export default ShopListing;
