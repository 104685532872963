import React from "react";

const ArticleEvent_En = () => {
  return (
    <>
      <div class="card mb-3 CastShadow">
        <div class="row g-0">
          <div class="col-md-4">
            <img
              src="/img/ThumbNail2_KR.webp"
              class="img-fluid rounded-start"
              alt="..."
            />
          </div>
          <div class="col-md-8">
            <div class="card-body">
              <h5 class="card-title maplebold">
                Kartrider: Drift Developer JL's Gift
              </h5>
              <p class="card-text maplelight">
                Coupon number: F481NKBRIYS5 ∙ Coupon validity period: May 11,
                2023 (Thu) morning Until 9:00 (UTC +9) ∙ Reward: 5,000 Lucci
              </p>
              <p class="card-text maplelight">
                <small class="text-muted">Posted: March 1, 2023</small>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ArticleEvent_En;
