import React from "react";

const Footer = () => {
  return (
    <footer className="bg-dark text-white p-3 text-center maplelight">
      Site Made for Personal Study Material, it is to be further polished with
      regular upates
      {/* 개인 학습 자료용으로 제작된 사이트입니다. 기능및 정기
      업데이트가 추가될 예정입니다. */}
    </footer>
  );
};

export default Footer;
