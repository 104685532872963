import React from "react";

const ArticleCard8 = () => {
  return (
    <>
      <div
        class="card mb-3 CastShadow"
        data-bs-toggle="modal"
        data-bs-target="#exampleModal9"
      >
        <div class="row g-0">
          <div class="col-md-4">
            <img
              src="/img/ThumbNail11.webp"
              class="img-fluid rounded-start"
              alt="..."
            />
          </div>
          <div class="col-md-8">
            <div class="card-body">
              <div class="card-title maplebold ArticleHeader">
                KartRider: Drift x MapleStory!
              </div>
              <p class="card-text maplelight">
                The wonders of Maple World have arrived to KartRider: Drift,
                with two classic MapleStory characters making their debuts on
                the track!
              </p>
              <p class="card-text maplelight">
                <small class="text-muted">Posted: March 23, 2023</small>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        class="modal fade"
        id="exampleModal9"
        tabindex="-1"
        aria-labelledby="exampleModalLabel9"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5 maplebold" id="exampleModalLabel10">
                KartRider: Drift x MapleStory!
              </h1>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <img src="/img/ThumbNail11.webp" class="img-fluid" alt="..." />
            <div class="modal-body maplelight">
              The wonders of Maple World have arrived to KartRider: Drift, with
              two classic MapleStory characters making their debuts on the
              track!
            </div>
            <img
              src="/img/KartCharSkin/CharSkin31.webp"
              class="img-fluid"
              alt="..."
            />
            <div class="modal-body maplelight">
              "Pink Bean" from MapleStory joins the ride! Aswell as...
            </div>
            <img
              src="/img/KartCharSkin/CharSkin32.webp"
              class="img-fluid"
              alt="..."
            />
            <div class="modal-body maplelight">
              "Lucid" the beloved boss from the Nightmare Clocktower!
            </div>
            <div class="modal-body maplelight">
              Meet both of these new riders at the Shop!
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button type="button" class="btn btn-danger">
                Report
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ArticleCard8;
