import React from "react";

const ArticleCard7 = () => {
  return (
    <>
      <div
        class="card mb-3 CastShadow"
        data-bs-toggle="modal"
        data-bs-target="#exampleModal7"
      >
        <div class="row g-0">
          <div class="col-md-4">
            <img
              src="/img/ThumbNail9.webp"
              class="img-fluid rounded-start"
              alt="..."
            />
          </div>
          <div class="col-md-8">
            <div class="card-body">
              <div class="card-title maplebold ArticleHeader">March 15 Item Shop Update</div>
              <p class="card-text maplelight">
                A new set of sales are here, with Tobi's most delicious look
                here to charm everyone on the track!
              </p>
              <p class="card-text maplelight">
                <small class="text-muted">Posted: March 17, 2023</small>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        class="modal fade"
        id="exampleModal7"
        tabindex="-1"
        aria-labelledby="exampleModalLabel7"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5 maplebold" id="exampleModalLabel10">
                KMarch 15 Item Shop Update
              </h1>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <img src="/img/ThumbNail9.webp" class="img-fluid" alt="..." />
            <div class="modal-body maplelight">
              A new set of sales are here, with Tobi's most delicious look here
              to charm everyone on the track!
            </div>
            <div class="modal-body maplelight">
              Toby's new skin, "Cupcake Toby", has been added with the cost of
              500 K-COIN.
            </div>
            <img
              src="/img/KartCharSkin/CharSkin29.webp"
              class="img-fluid"
              alt="..."
            />
            <div class="modal-body maplelight">
              "911 Turbo S Cabriolet" rarity "rare" has been added for 800
              K-COIN.
            </div>
            <img
              src="/img/KartBody/KartBodyPorsche911.webp"
              class="img-fluid"
              alt="..."
            />
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button type="button" class="btn btn-danger">
                Report
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ArticleCard7;
