import React from "react";

const KartBodyDex = () => {
  return (
    <>
      <div className="featured-news">
        <title>KartRider Drift: Kart Body Encyclopedia</title>
        <meta
          name="description"
          content="Kartrider drift all kart / kartbody"
        ></meta>
        <h1 class="text-primary kotrablod">Kart Encyclopedia</h1>
        <p1 class="text-muted kotrablod">Last Updated:2023-15-03</p1>
        <div class="row mx-0 row-cols-2 row-cols-md-4 gy-4 featured-news">
          {/* card 1 */}
          <div class="col ">
            {" "}
            <div class="card h-100">
              <img
                src="/img/KartBody/KartBodyPorsche911.webp"
                class="card-img-top"
                alt="..."
              />
              <div class="card-body">
                <h5 class="card-title kotrablod">911 Turbo S Cabriolet</h5>
                <p class="card-text">
                  "Incredible performance, whilst being comfortablea and fully
                  suitable for everyday use. The 911 Turbo models continue this
                  philosophy"
                </p>
              </div>
              <div class="card-footer">
                <small class="text-muted">Obtainable by: Season 1 Shop</small>
              </div>
            </div>
          </div>
          {/* card 1 */}
          <div class="col ">
            {" "}
            <div class="card h-100">
              <img
                src="/img/KartBody/KartBody718Boxter.webp"
                class="card-img-top"
                alt="..."
              />
              <div class="card-body">
                <h5 class="card-title kotrablod">718 Boxster</h5>
                <p class="card-text">
                  "Mid-engined roadsters that unite the sporting spirit of the
                  legendary Porsche 718 with the sports car of tomorrow and
                  transfer it to the roads of today's world."
                </p>
              </div>
              <div class="card-footer">
                <small class="text-muted">Obtainable by: Season 1 Login</small>
              </div>
            </div>
          </div>
          {/* card 1 */}
          <div class="col ">
            {" "}
            <div class="card h-100">
              <img
                src="/img/KartBody/KartBodyNewyorkbus.webp"
                class="card-img-top"
                alt="..."
              />
              <div class="card-body">
                <h5 class="card-title kotrablod">NYC Tour Bus</h5>
                <p class="card-text">
                  "Tour tickets are sold out! See New York up close."
                </p>
              </div>
              <div class="card-footer">
                <small class="text-muted">Obtainable by: Shop</small>
              </div>
            </div>
          </div>
          {/* card 1 */}
          <div class="col ">
            {" "}
            <div class="card h-100">
              <img
                src="/img/KartBody/KartBodyNewyorktaxi.webp"
                class="card-img-top"
                alt="..."
              />
              <div class="card-body">
                <h5 class="card-title kotrablod">New York Taxi</h5>
                <p class="card-text">
                  "No one knows the ins and outs of the city streets like a taxi
                  driver."
                </p>
              </div>
              <div class="card-footer">
                <small class="text-muted">Obtainable by: Shop</small>
              </div>
            </div>
          </div>
          {/* card 1 */}
          <div class="col ">
            {" "}
            <div class="card h-100">
              <img
                src="/img/KartBody/KartBody20.webp"
                class="card-img-top"
                alt="..."
              />
              <div class="card-body">
                <h5 class="card-title kotrablod">Quantum Muscle</h5>
                <p class="card-text">"..."</p>
              </div>
              <div class="card-footer">
                <small class="text-muted">
                  Obtainable by: Playstation Bonus
                </small>
              </div>
            </div>
          </div>
          {/* card 1 */}
          <div class="col ">
            {" "}
            <div class="card h-100">
              <img
                src="/img/KartBody/KartBody21.webp"
                class="card-img-top"
                alt="..."
              />
              <div class="card-body">
                <h5 class="card-title kotrablod">Cosmic Muscle</h5>
                <p class="card-text">"..."</p>
              </div>
              <div class="card-footer">
                <small class="text-muted">Obtainable by: Xbox Bonus</small>
              </div>
            </div>
          </div>
          {/* card 1 */}
          <div class="col ">
            {" "}
            <div class="card h-100">
              <img
                src="/img/KartBody/KartBody22.webp"
                class="card-img-top"
                alt="..."
              />
              <div class="card-body">
                <h5 class="card-title kotrablod">Tunnel Master</h5>
                <p class="card-text">"..."</p>
              </div>
              <div class="card-footer">
                <small class="text-muted">Obtainable by: Shop</small>
              </div>
            </div>
          </div>
          {/* card 1 */}
          <div class="col ">
            {" "}
            <div class="card h-100">
              <img
                src="/img/KartBody/KartBody23.webp"
                class="card-img-top"
                alt="..."
              />
              <div class="card-body">
                <h5 class="card-title kotrablod">Speed Reader</h5>
                <p class="card-text">
                  "Never stop learning. Not even when you're racing"
                </p>
              </div>
              <div class="card-footer">
                <small class="text-muted">Obtainable by: Shop</small>
              </div>
            </div>
          </div>
          {/* card 1 */}
          <div class="col ">
            {" "}
            <div class="card h-100">
              <img
                src="/img/KartBody/KartBodyBrutus.webp"
                class="card-img-top"
                alt="..."
              />
              <div class="card-body">
                <h5 class="card-title kotrablod">Brutus</h5>
                <p class="card-text">
                  "Outrace the competition with overwhelming power!"
                </p>
              </div>
              <div class="card-footer">
                <small class="text-muted">Obtainable by: Shop</small>
              </div>
            </div>
          </div>
          {/* card 2 */}
          <div class="col">
            <div class="card h-100">
              <img
                src="/img/KartBody/KartBodyLooloo.webp"
                class="card-img-top"
                alt="..."
              />
              <div class="card-body">
                <h5 class="card-title kotrablod">
                  Deuce Coupe
                  {/* 비밀 변기 */}
                </h5>
                <p class="card-text">
                  "Flush out the competition with the best seat in town!""
                  {/* "변기로 위장한 비밀 병기. 드디어... 드디어 출격이다!" */}
                </p>
              </div>
              <div class="card-footer">
                <small class="text-muted">Obtainable by: Shop</small>
              </div>
            </div>
          </div>
          {/* card 3 */}
          <div class="col">
            <div class="card h-100">
              <img
                src="/img/KartBody/KartBodyTitan.webp"
                class="card-img-top"
                alt="..."
              />
              <div class="card-body">
                <h5 class="card-title kotrablod">
                  Titan
                  {/* 타이탄 */}
                </h5>
                <p class="card-text">
                  "The roar of this truck's monstrous engine resounds for miles
                  around"
                  {/* "몬스터 트럭의 포효! 트랙과 오프로드 곳곳에 울려 퍼진다." */}
                </p>
              </div>
              <div class="card-footer">
                <small class="text-muted">Obtainable by: Shop</small>
              </div>
            </div>
          </div>
          {/* card 4 */}
          <div class="col">
            <div class="card h-100">
              <img
                src="/img/KartBody/KartBodyAce.png"
                class="card-img-top"
                alt="..."
              />
              <div class="card-body">
                <h5 class="card-title kotrablod">
                  Ace
                  {/* 에이스 */}
                </h5>
                <p class="card-text">
                  "This classic biplane never goes out of style. Bombs away!"
                  {/* "복엽기의 부활, 에이스의 귀환! 이제 곧 미사일 폭격이 시작된다." */}
                </p>
              </div>
              <div class="card-footer">
                <small class="text-muted">Obtainable by: Shop</small>
              </div>
            </div>
          </div>
          {/* card 5 */}
          <div class="col">
            <div class="card h-100">
              <img
                src="/img/KartBody/KartBody12.webp"
                class="card-img-top"
                alt="..."
              />
              <div class="card-body">
                <h5 class="card-title kotrablod">
                  Storm
                  {/* 스톰 */}
                </h5>
                <p class="card-text">
                  "This Storm blows away all obstacles!"
                  {/* "거침없는 폭풍 러시! 최악의 악천후도 문제없다." */}
                </p>
              </div>
              <div class="card-footer">
                <small class="text-muted">Obtainable by: Shop</small>
              </div>
            </div>
          </div>
          {/* card 6 */}
          <div class="col">
            <div class="card h-100">
              <img
                src="/img/KartBody/KartBody11.webp"
                class="card-img-top"
                alt="..."
              />
              <div class="card-body">
                <h5 class="card-title kotrablod">
                  Mine Cart
                  {/* 광산 수레 */}
                </h5>
                <p class="card-text">"This cart really went off the rails!"</p>
              </div>
              <div class="card-footer">
                <small class="text-muted">Last Seen: Shop 23-01-07</small>
              </div>
            </div>
          </div>
          {/* card 7 */}
          <div class="col">
            <div class="card h-100">
              <img
                src="/img/KartBody/KartBody.webp"
                class="card-img-top"
                alt="..."
              />
              <div class="card-body">
                <h5 class="card-title kotrablod">Able</h5>
                <p class="card-text">
                  Exclusive to IOS device. Appeats differently on other
                  platforms.
                </p>
              </div>
              <div class="card-footer">
                <small class="text-muted">Last Seen: Shop 23-01-07</small>
              </div>
            </div>
          </div>
          {/* card 8 */}
          <div class="col">
            <div class="card h-100">
              <img
                src="/img/KartBody/KartBody2.webp"
                class="card-img-top"
                alt="..."
              />
              <div class="card-body">
                <h5 class="card-title kotrablod">Android</h5>
                <p class="card-text">
                  Exclusive to Android device. Appeats differently on other
                  platforms.
                </p>
              </div>
              <div class="card-footer">
                <small class="text-muted">Last Seen: Shop 23-01-07</small>
              </div>
            </div>
          </div>
          {/* card 9 */}
          <div class="col">
            <div class="card h-100">
              <img
                src="/img/KartBody/KartBody3.webp"
                class="card-img-top"
                alt="..."
              />
              <div class="card-body">
                <h5 class="card-title kotrablod">
                  Nexon Kart
                  {/* 넥슨 카트 */}
                </h5>
                <p class="card-text">
                  "Excitement! Thrills! Drive with joy wherver you go!"
                  {/* 신나게, 더 짜릿하게! 언제 어디서나 누구든 즐겁게 달린다. */}
                </p>
              </div>
              <div class="card-footer">
                <small class="text-muted">Last Seen: Shop 23-01-07</small>
              </div>
            </div>
          </div>
          {/* card 10 */}
          <div class="col">
            <div class="card h-100">
              <img
                src="/img/KartBody/KartBody4.webp"
                class="card-img-top"
                alt="..."
              />
              <div class="card-body">
                <h5 class="card-title kotrablod">
                  Justice
                  {/* 저스티스 */}
                </h5>
                <p class="card-text">
                  "Patrol the streets on the four wheels of Justice"
                  {/* 트랙 위의 다크 히어로. 냉혹하게 레이스를 지배한다 */}
                </p>
              </div>
              <div class="card-footer">
                <small class="text-muted">Last Seen: Shop 23-01-07</small>
              </div>
            </div>
          </div>
          {/* card 11 */}
          <div class="col">
            <div class="card h-100">
              <img
                src="/img/KartBody/KartBody5.webp"
                class="card-img-top"
                alt="..."
              />
              <div class="card-body">
                <h5 class="card-title kotrablod">
                  Cotton
                  {/* 코튼 */}
                </h5>
                <p class="card-text">
                  "An iconic kart that's balanced for versatility"
                  {/* 탁월한 밸런스! 균형잡인 퍼포먼스가 펼쳐진다. */}
                </p>
              </div>
              <div class="card-footer">
                <small class="text-muted">Last Seen: Shop 23-01-07</small>
              </div>
            </div>
          </div>
          {/* card 12 */}
          <div class="col">
            <div class="card h-100">
              <img
                src="/img/KartBody/KartBody6.webp"
                class="card-img-top"
                alt="..."
              />
              <div class="card-body">
                <h5 class="card-title kotrablod">
                  Practice Kart
                  {/* 연습 카트 */}
                </h5>
                <p class="card-text">
                  "It takes practice to become a pro"
                  {/* 프로를 향한 첫걸음 연습이 프로를 만든다 */}
                </p>
              </div>
              <div class="card-footer">
                <small class="text-muted">Last Seen: Shop 23-01-07</small>
              </div>
            </div>
          </div>
          {/* card 13 */}
          <div class="col">
            <div class="card h-100">
              <img
                src="/img/KartBody/KartBody7.webp"
                class="card-img-top"
                alt="..."
              />
              <div class="card-body">
                <h5 class="card-title kotrablod">
                  Burst
                  {/* 버스트 */}
                </h5>
                <p class="card-text">
                  "Start the race wioth a burst of acceleration"
                  {/* 폭팔적인 가속도! 스타트부터 맹렬히 돌진한다. */}
                </p>
              </div>
              <div class="card-footer">
                <small class="text-muted">Last Seen: Shop 23-01-07</small>
              </div>
            </div>
          </div>
          {/* card 14 */}
          <div class="col">
            <div class="card h-100">
              <img
                src="/img/KartBody/KartBody8.webp"
                class="card-img-top"
                alt="..."
              />
              <div class="card-body">
                <h5 class="card-title kotrablod">
                  Aurora
                  {/* 오로라 */}
                </h5>
                <p class="card-text">
                  "A flash of dazzling light races across the track!"
                  {/* 어두운 세상을 밝힐 신의 장막. 환상적인 아름다움을 뿜내며 트랙을
                내달린다. */}
                </p>
              </div>
              <div class="card-footer">
                <small class="text-muted">Last Seen: Shop 23-01-07</small>
              </div>
            </div>
          </div>
          {/* card 15 */}
          <div class="col">
            <div class="card h-100">
              <img
                src="/img/KartBody/KartBody9.webp"
                class="card-img-top"
                alt="..."
              />
              <div class="card-body">
                <h5 class="card-title kotrablod">
                  Shellback Raider
                  {/* 해적 거북 */}
                </h5>
                <p class="card-text">
                  "This piratical turtle is the perfect companion to conquer
                  both land and sea"
                  {/* 그냥 거북이가 아니다. 바다와 트랙 모두 정복할
                해적이다. */}
                </p>
              </div>
              <div class="card-footer">
                <small class="text-muted">Last Seen: Shop 23-01-07</small>
              </div>
            </div>
          </div>
          {/* card 16 */}
          <div class="col">
            <div class="card h-100">
              <img
                src="/img/KartBody/KartBody10.webp"
                class="card-img-top"
                alt="..."
              />
              <div class="card-body">
                <h5 class="card-title kotrablod">
                  SpiderKart
                  {/* 거미 카트 */}
                </h5>
                <p class="card-text">???</p>
              </div>
              <div class="card-footer">
                <small class="text-muted">Unreleased</small>
              </div>
            </div>
          </div>
          {/* card 17 */}
          <div class="col">
            <div class="card h-100">
              <img
                src="/img/KartBody/KartBody17.webp"
                class="card-img-top"
                alt="..."
              />
              <div class="card-body">
                <h5 class="card-title kotrablod">
                  Proto
                  {/* 프로토 */}
                </h5>
                <p class="card-text">
                  "A kart so advanced, so high tech, so cutting edge, you'll
                  swear it's from the future"
                  {/* 미래를 관통하는 바디. 새로운 엔진과 최적화를 예고한다. */}
                </p>
              </div>
              <div class="card-footer">
                <small class="text-muted">Last Seen: Shop 23-01-07</small>
              </div>
            </div>
          </div>
          {/* card 18 */}
          <div class="col">
            <div class="card h-100">
              <img
                src="/img/KartBody/KartBody14.webp"
                class="card-img-top"
                alt="..."
              />
              <div class="card-body">
                <h5 class="card-title kotrablod">
                  Fire Engine
                  {/* 소방차 */}
                </h5>
                <p class="card-text">
                  "It's an emergency! Defend the track and protect lives"
                  {/* 긴급 상황, 출동! 트랙을 사수하고 반드시 생명을 지킨다. */}
                </p>
              </div>
              <div class="card-footer">
                <small class="text-muted">Last Seen: Shop 23-01-07</small>
              </div>
            </div>
          </div>
          {/* card 19 */}
          <div class="col">
            <div class="card h-100">
              <img
                src="/img/KartBody/KartBody15.webp"
                class="card-img-top"
                alt="..."
              />
              <div class="card-body">
                <h5 class="card-title kotrablod">
                  Marathon
                  {/* 마라톤 */}
                </h5>
                <p class="card-text">
                  "42.195km From the starting line to the photo finish, this
                  kart doesn't quit."
                  {/* 42.195km, 단 한순간도 지치지 않고, 백번 천번 내달린다. */}
                </p>
              </div>
              <div class="card-footer">
                <small class="text-muted">Last Seen: Shop 23-01-07</small>
              </div>
            </div>
          </div>
          {/* card 20 */}
          <div class="col">
            <div class="card h-100">
              <img
                src="/img/KartBody/KartBody16.webp"
                class="card-img-top"
                alt="..."
              />
              <div class="card-body">
                <h5 class="card-title kotrablod">
                  Bunny Buggy
                  {/* 슈가래빗 */}
                </h5>
                <p class="card-text">
                  "This kart will make you appreciate the power of a fresh,
                  crispy carrot."
                  {/* 달달란 당근의 힘! 발바닥에 강력한 부스터가 샘솟는다. */}
                </p>
              </div>
              <div class="card-footer">
                <small class="text-muted">Last Seen: Shop 23-01-07</small>
              </div>
            </div>
          </div>
          {/* card 21 */}
          <div class="col">
            <div class="card h-100">
              <img
                src="/img/KartBody/KartBody18.webp"
                class="card-img-top"
                alt="..."
              />
              <div class="card-body">
                <h5 class="card-title kotrablod">
                  Saber
                  {/* 세이버 */}
                </h5>
                <p class="card-text">
                  "Such an agile drifter! Even Saber's tire marks are unique."
                  {/* 날렵한 드리프트! 세이버의 스키드 마크는 언제나 특별하다. */}
                </p>
              </div>
              <div class="card-footer">
                <small class="text-muted">Last Seen: Shop 23-01-07</small>
              </div>
            </div>
          </div>
          {/* card 22 */}
          <div class="col">
            <div class="card h-100">
              <img
                src="/img/KartBody/KartBody13.webp"
                class="card-img-top"
                alt="..."
              />
              <div class="card-body">
                <h5 class="card-title kotrablod">
                  Spike
                  {/* 스파이크 */}
                </h5>
                <p class="card-text">
                  "Just say no to air resistance!"
                  {/* 공기저항 이상무! 그 무엇도 레이싱 방해하지 못한다. */}
                </p>
              </div>
              <div class="card-footer">
                <small class="text-muted">Last Seen: Shop 23-01-07</small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default KartBodyDex;
