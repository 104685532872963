import React from "react";

const ArticleCard8 = () => {
  return (
    <>
      <div
        class="card mb-3 CastShadow"
        data-bs-toggle="modal"
        data-bs-target="#exampleModal8"
      >
        <div class="row g-0">
          <div class="col-md-4">
            <img
              src="/img/ThumbNail10.webp"
              class="img-fluid rounded-start"
              alt="..."
            />
          </div>
          <div class="col-md-8">
            <div class="card-body">
              <div class="card-title maplebold ArticleHeader">
                Scrap Tobi Weekend Login Bonus
              </div>
              <p class="card-text maplelight">
                Get a special login reward this weekend with a rusty look for
                one of the newest characters in KartRider: Drift!
              </p>
              <p class="card-text maplelight">
                <small class="text-muted">Posted: March 17, 2023</small>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        class="modal fade"
        id="exampleModal8"
        tabindex="-1"
        aria-labelledby="exampleModalLabel8"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5 maplebold" id="exampleModalLabel10">
                Scrap Tobi Weekend Login Bonus
              </h1>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <img src="/img/ThumbNail10.webp" class="img-fluid" alt="..." />
            <div class="modal-body maplelight">
              Get a special login reward this weekend with a rusty look for one
              of the newest characters in KartRider: Drift!
            </div>
            <img
              src="/img/KartCharSkin/Scrappy Tobi.webp"
              class="img-fluid"
              alt="..."
            />
            <div class="modal-body maplelight">
              Event Duration: PDT (UTC -7): Thursday, March 16, 2023 5:00 PM -
              Sunday, March 19, 2023 5:00 PM CET (UTC +1): Friday, March 17,
              2023 1:00 AM - Monday, March 20, 2023 1:00 AM JST (UTC +9):
              Friday, March 17, 2023 9:00 AM - Monday, March 20, 2023 9:00 AM
              AEDT (UTC +11): Friday, March 17, 2023 11:00 AM - Monday, March
              20, 2023 11:00 AM Event Details: Log into KartRider: Drift during
              this weekend and claim special rewards! Rewards are specific to
              the day, and will be skipped if you do not log in during that day.
              Prizes must be claimed from the event menu before the event ends,
              and all items sent to the Mailbox must be claimed within 30 days
              of receipt, or they will expire. Days begin and end at 12:00 AM
              UTC on the noted day. Event Rewards: Friday, March 17 Reward:
              1,000 Lucci Saturday, March 18 Reward: Scrap Tobi Sunday, March 19
              Reward: 2,000 Lucci
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button type="button" class="btn btn-danger">
                Report
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ArticleCard8;
