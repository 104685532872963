import React from "react";

const ArticleCard4_En = () => {
  return (
    <>
      <div
        class="card mb-3 CastShadow"
        data-bs-toggle="modal"
        data-bs-target="#exampleModal4"
      >
        <div class="row g-0">
          <div class="col-md-4">
            <img
              src="/img/ThumbNail1_EN.webp"
              class="img-fluid rounded-start"
              alt="..."
            />
          </div>
          <div class="col-md-8">
            <div class="card-body">
              <div class="card-title maplebold ArticleHeader">
                Kartrider: Drift 2/16 Balance Patch
              </div>
              <p class="card-text maplelight">
                Patch has been made, improved License Exams, balanced all ingame
                karts to have equal speed and feel, you can now change your
                account name.
              </p>
              <p class="card-text maplelight">
                <small class="text-muted">Posted: Feb 15, 2023</small>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        class="modal fade"
        id="exampleModal4"
        tabindex="-1"
        aria-labelledby="exampleModalLabel4"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5 maplebold" id="exampleModalLabel10">
                Kartrider: Drift 2/16 Balance Patch
              </h1>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <img src="/img/ThumbNail1_EN.webp" class="img-fluid" alt="..." />
            <div class="modal-body maplelight">
              Patch has been made, improved License Exams, balanced all ingame
              karts to have equal speed and feel, you can now change your
              account name.
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button type="button" class="btn btn-danger">
                Report
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ArticleCard4_En;
