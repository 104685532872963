import React from "react";

const CharSkinDex = () => {
  return (
    <div className="featured-news">
      <title>KartRider Drift All Character & Character Skin</title>{" "}
      <meta
        name="description"
        content="Kartrider drift all character skin"
      ></meta>
      <h1 class="text-primary kotrablod">Character Skin Encyclopedia</h1>
      <p1 class="text-muted kotrablod">Last Updated: 2023-13-03</p1>
      <div class="row mx-0 row-cols-2 row-cols-md-4 gy-4 featured-news">
        <div class="col">
          <div class="card h-100">
            <img
              src="/img/KartCharSkin/CharSkin32.webp"
              class="card-img-top"
              alt="..."
            />
            <div class="card-body">
              <h5 class="card-title kotrablod">Lucid</h5>
              <p class="card-text maplelight">Obtainable by: Shop</p>
            </div>
            <div class="card-footer">
              <small class="text-muted maplelight">
                Skin Release: 2022-03-23
              </small>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card h-100">
            <img
              src="/img/KartCharSkin/CharSkin31.webp"
              class="card-img-top"
              alt="..."
            />
            <div class="card-body">
              <h5 class="card-title kotrablod">Pink Bean</h5>
              <p class="card-text maplelight">Obtainable by: Shop</p>
            </div>
            <div class="card-footer">
              <small class="text-muted maplelight">
                Skin Release: 2022-03-23
              </small>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card h-100">
            <img
              src="/img/KartCharSkin/CharSkin24.webp"
              class="card-img-top"
              alt="..."
            />
            <div class="card-body">
              <h5 class="card-title kotrablod">Snow Town Vivi</h5>
              <p class="card-text maplelight">Obtainable by: Season1 Pass</p>
            </div>
            <div class="card-footer">
              <small class="text-muted maplelight">
                Skin Release: 2022-01-01
              </small>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card h-100">
            <img
              src="/img/KartCharSkin/CharSkin23.webp"
              class="card-img-top"
              alt="..."
            />
            <div class="card-body">
              <h5 class="card-title kotrablod">Snow Town Pim</h5>
              <p class="card-text maplelight">
                Obtainable by: Season1 Login Bonus
              </p>
            </div>
            <div class="card-footer">
              <small class="text-muted maplelight">
                Skin Release: 2022-01-01
              </small>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card h-100">
            <img
              src="/img/KartCharSkin/CharSkin22.webp"
              class="card-img-top"
              alt="..."
            />
            <div class="card-body">
              <h5 class="card-title kotrablod">Miss Miso</h5>
              <p class="card-text maplelight">Obtainable by: Season1 Pass</p>
            </div>
            <div class="card-footer">
              <small class="text-muted maplelight">
                Skin Release: 2022-01-01
              </small>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card h-100">
            <img
              src="/img/KartCharSkin/CharSkin21.webp"
              class="card-img-top"
              alt="..."
            />
            <div class="card-body">
              <h5 class="card-title kotrablod">Android Tobi</h5>
              <p class="card-text maplelight">Method: Season1 Pass</p>
            </div>
            <div class="card-footer">
              <small class="text-muted maplelight">
                Skin Release: 2022-01-01
              </small>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card h-100">
            <img
              src="/img/KartCharSkin/CharSkin20.webp"
              class="card-img-top"
              alt="..."
            />
            <div class="card-body">
              <h5 class="card-title kotrablod">Small Uni</h5>
              <p class="card-text maplelight">Method: PCcafe Bonus</p>
            </div>
            <div class="card-footer">
              <small class="text-muted maplelight">
                Skin Release: 2022-01-01
              </small>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card h-100">
            <img
              src="/img/KartCharSkin/CharSkin19.webp"
              class="card-img-top"
              alt="..."
            />
            <div class="card-body">
              <h5 class="card-title kotrablod">Outlaw Brodi</h5>
              <p class="card-text maplelight">Method: PCcafe Bonus</p>
            </div>
            <div class="card-footer">
              <small class="text-muted maplelight">
                Skin Release: 2022-01-01
              </small>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card h-100">
            <img
              src="/img/KartCharSkin/CharSkin18.webp"
              class="card-img-top"
              alt="..."
            />
            <div class="card-body">
              <h5 class="card-title kotrablod">Cabbie Brodi</h5>
              <p class="card-text maplelight">Method: Shop</p>
            </div>
            <div class="card-footer">
              <small class="text-muted maplelight">
                Skin Release: 2022-01-01
              </small>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card h-100">
            <img
              src="/img/KartCharSkin/CharSkin17.webp"
              class="card-img-top"
              alt="..."
            />
            <div class="card-body">
              <h5 class="card-title kotrablod">Bazzi of Liberty</h5>
              <p class="card-text maplelight">Method: Shop</p>
            </div>
            <div class="card-footer">
              <small class="text-muted maplelight">
                Skin Release: 2022-01-01
              </small>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card h-100">
            <img
              src="/img/KartCharSkin/CharSkin16.webp"
              class="card-img-top"
              alt="..."
            />
            <div class="card-body">
              <h5 class="card-title kotrablod">Pine Scout Vivi</h5>
              <p class="card-text maplelight">Method: Shop</p>
            </div>
            <div class="card-footer">
              <small class="text-muted maplelight">
                Skin Release: 2022-01-01
              </small>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card h-100">
            <img
              src="/img/KartCharSkin/CharSkin15.webp"
              class="card-img-top"
              alt="..."
            />
            <div class="card-body">
              <h5 class="card-title kotrablod">Prep School Rave</h5>
              <p class="card-text maplelight">Method: Shop</p>
            </div>
            <div class="card-footer">
              <small class="text-muted maplelight">
                Skin Release: 2022-01-01
              </small>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card h-100">
            <img
              src="/img/KartCharSkin/CharSkin1.webp"
              class="card-img-top"
              alt="..."
            />
            <div class="card-body">
              <h5 class="card-title kotrablod">
                Phantom Theif Kris
                {/* 괴도 크리스 */}
              </h5>
              <p class="card-text maplelight">
                Method: Shop
                {/* 획득 경로: 상점 */}
              </p>
            </div>
            <div class="card-footer">
              <small class="text-muted maplelight">
                Skin Release: 2022-07-01
                {/* 스킨 출시일: 2023-01-07 */}
              </small>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card h-100">
            <img
              src="/img/KartCharSkin/CharSkin2.webp"
              class="card-img-top"
              alt="..."
            />
            <div class="card-body">
              <h5 class="card-title kotrablod">
                Chef Mos
                {/* 요리사 모스 */}
              </h5>
              <p class="card-text ">
                Method: Shop
                {/* 획득 경로: 상점 */}
              </p>
            </div>
            <div class="card-footer">
              <small class="text-muted maplelight">
                Skin Release: 2022-01-01
              </small>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card h-100">
            <img
              src="/img/KartCharSkin/CharSkin3.webp"
              class="card-img-top"
              alt="..."
            />
            <div class="card-body">
              <h5 class="card-title kotrablod">
                Pro Baller Bazzi
                {/* 슈팅 가드 배찌 */}
              </h5>
              <p class="card-text">
                Method: Shop
                {/* 획득 경로: 상점 */}
              </p>
            </div>
            <div class="card-footer">
              <small class="text-muted maplelight">
                Skin Release: 2023-07-01
                {/* 스킨 출시일: 2023-01-07 */}
              </small>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card h-100">
            <img
              src="/img/KartCharSkin/CharSkin4.webp"
              class="card-img-top"
              alt="..."
            />
            <div class="card-body">
              <h5 class="card-title kotrablod">
                Power Forward Rex
                {/* 파워 포드 렉스 */}
              </h5>
              <p class="card-text">
                Method: Shop
                {/* 획득 경로: 상점 */}
              </p>
            </div>
            <div class="card-footer">
              <small class="text-muted maplelight">
                Release Date: 2023-07-01
                {/* 스킨 출시일: 2023-01-07 */}
              </small>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card h-100">
            <img
              src="/img/KartCharSkin/CharSkin13.webp"
              class="card-img-top"
              alt="..."
            />
            <div class="card-body">
              <h5 class="card-title kotrablod">
                Class President Marid
                {/* 세일러 마리드 */}
              </h5>
              <p class="card-text">
                Method: Shop
                {/* 획득 경로: 상점 */}
              </p>
            </div>
            <div class="card-footer">
              <small class="text-muted maplelight">
                Skin Release: 2023-07-01
                {/* 스킨 출시일: 2023-01-07 */}
              </small>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card h-100">
            <img
              src="/img/KartCharSkin/CharSkin5.webp"
              class="card-img-top"
              alt="..."
            />
            <div class="card-body">
              <h5 class="card-title kotrablod">
                Model Student Diz
                {/* 모범생 디지니 */}
              </h5>
              <p class="card-text">
                Method: Pre-Registration Event
                {/* 획득 경로: 사전등록 이벤트 보상 */}
              </p>
            </div>
            <div class="card-footer">
              <small class="text-muted maplelight">
                Skin Relase: 2023-01-01
                {/* 스킨 출시일: 2023-01-07 */}
              </small>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card h-100">
            <img
              src="/img/KartCharSkin/CharSkin6.webp"
              class="card-img-top"
              alt="..."
            />
            <div class="card-body">
              <h5 class="card-title kotrablod">
                Pit Chief Brodi
                {/* 총감독 브로디 */}
              </h5>
              <p class="card-text">
                Method: 3rd CBT Reward
                {/* 획득 경로: 3차 CBT 보상 */}
              </p>
            </div>
            <div class="card-footer">
              <small class="text-muted maplelight">
                Skin Release: 2023-01-01
                {/* 스킨 출시일: 2023-01-07 */}
              </small>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card h-100">
            <img
              src="/img/KartCharSkin/CharSkin7.webp"
              class="card-img-top"
              alt="..."
            />
            <div class="card-body">
              <h5 class="card-title kotrablod">
                Necro Neo
                {/* 다크 히어로 네오 */}
              </h5>
              <p class="card-text">
                Method: Pre-Season Racing Pass Reward
                {/* 획득 경로: 프리시즌 레이싱 패스 */}
              </p>
            </div>
            <div class="card-footer">
              <small class="text-muted maplelight">
                Skin Release: 2023-01-01
                {/* 스킨 출시일: 2023-01-01 */}
              </small>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card h-100">
            <img
              src="/img/KartCharSkin/CharSkin8.webp"
              class="card-img-top"
              alt="..."
            />
            <div class="card-body">
              <h5 class="card-title kotrablod">Striker Diz</h5>
              <p class="card-text">
                Method: Pre-Season Racing Pass Reward
                {/* 획득 경로: 프리시즌 레이싱패스 */}
              </p>
            </div>
            <div class="card-footer">
              <small class="text-muted maplelight">
                Skin Release: 2023-01-01
                {/* 스킨 출시일: 2023-01-07 */}
              </small>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card h-100">
            <img
              src="/img/KartCharSkin/CharSkin9.webp"
              class="card-img-top"
              alt="..."
            />
            <div class="card-body">
              <h5 class="card-title kotrablod">
                Tennis Star Rave
                {/* 테니스 선수 레이브 */}
              </h5>
              <p class="card-text">
                Method: Pre-Season Racing Pass Reward
                {/* 획득 경로: 프리시즌 레이싱 패스 */}
              </p>
            </div>
            <div class="card-footer">
              <small class="text-muted maplelight">
                Skin Release: 2023-01-01
                {/* 스킨 출시일: 2023-01-07 */}
              </small>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card h-100">
            <img
              src="/img/KartCharSkin/CharSkin10.webp"
              class="card-img-top"
              alt="..."
            />
            <div class="card-body">
              <h5 class="card-title kotrablod">
                Home Run King Dao
                {/* 홈런왕 다오 */}
              </h5>
              <p class="card-text">
                Method: Pre-Season Racing Pass Reward
                {/* 획득 경로: 프리시즌 레이싱 패스 */}
              </p>
            </div>
            <div class="card-footer">
              <small class="text-muted maplelight">
                Skin Release: 2023-01-01
                {/* 스킨 출시일: 2023-01-07 */}
              </small>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card h-100">
            <img
              src="/img/KartCharSkin/CharSkin11.webp"
              class="card-img-top"
              alt="..."
            />
            <div class="card-body">
              <h5 class="card-title kotrablod">
                Fireman Rex
                {/* 소방관 렉스 */}
              </h5>
              <p class="card-text">
                Method: Pre-Season Racing Pass Reward
                {/* 획득 경로: 프리시즌 레이싱 패스 */}
              </p>
            </div>
            <div class="card-footer">
              <small class="text-muted maplelight">
                Skin Release: 2023-01-07
                {/* 스킨 출시일: 2023-01-07 */}
              </small>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card h-100">
            <img
              src="/img/KartCharSkin/CharSkin12.webp"
              class="card-img-top"
              alt="..."
            />
            <div class="card-body">
              <h5 class="card-title kotrablod">
                Cactus Keffy
                {/* 선인장 케피 */}
              </h5>
              <p class="card-text">
                Method: Pre-Season Racing Pass Reward
                {/* 획득 경로: 프리시즌 레이싱 패스 */}
              </p>
            </div>
            <div class="card-footer">
              <small class="text-muted maplelight">
                Skin Release: 2023-01-01
                {/* 스킨 출시일: 2023-01-07 */}
              </small>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card h-100">
            <img
              src="/img/KartCharSkin/CharSkin14.webp"
              class="card-img-top"
              alt="..."
            />
            <div class="card-body">
              <h5 class="card-title kotrablod">
                Adventurer Brian
                {/* 에이스 모험가 브라이언 */}
              </h5>
              <p class="card-text">
                Method: Shop
                {/* 획득 경로: 상점 */}
              </p>
            </div>
            <div class="card-footer">
              <small class="text-muted maplelight">
                Skin Release: 2023-01-01
                {/* 스킨 출시일: 2023-01-07 */}
              </small>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CharSkinDex;
